// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-botm-js": () => import("./../../../src/pages/work/botm.js" /* webpackChunkName: "component---src-pages-work-botm-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-kbc-js": () => import("./../../../src/pages/work/kbc.js" /* webpackChunkName: "component---src-pages-work-kbc-js" */),
  "component---src-pages-work-mediamarkt-js": () => import("./../../../src/pages/work/mediamarkt.js" /* webpackChunkName: "component---src-pages-work-mediamarkt-js" */),
  "component---src-pages-work-playar-js": () => import("./../../../src/pages/work/playar.js" /* webpackChunkName: "component---src-pages-work-playar-js" */),
  "component---src-pages-work-vitas-js": () => import("./../../../src/pages/work/vitas.js" /* webpackChunkName: "component---src-pages-work-vitas-js" */)
}

